<template>
  <div class="container-full flex-col">
    <demo-header></demo-header>
    <calendly-widget></calendly-widget>
    <partner-feedback></partner-feedback>
  </div>
</template>

<script>
import DemoHeader from "@/components/public/demo/DemoHeader";
import CalendlyWidget from "@/components/public/demo/CalendlyWidget";
import PartnerFeedback from "@/components/public/PartnerFeedback";
export default {
  components: {
    DemoHeader,
    CalendlyWidget,
    PartnerFeedback
  }
};
</script>
