<template>
  <div
    class="calendly-inline-widget"
    data-url="https://calendly.com/gewodo/20mindemo"
  ></div>
</template>

<script>
export default {
  mounted() {
    const head = document.querySelector("head");
    const script = document.createElement("script");
    script.setAttribute(
      "src",
      "https://assets.calendly.com/assets/external/widget.js"
    );
    head.appendChild(script);
  }
};
</script>

<style lang="scss">
.calendly-inline-widget {
  @apply pt-2;
  min-height: 700px;
}
@media screen and (max-width: 992px) {
  .calendly-inline-widget {
    @apply pt-16;
    min-height: 650px;
    width: 100vw;
    iframe {
      min-height: 650px;
      width: 100vw;
    }
  }
}
@media screen and (max-width: 650px) {
  .calendly-inline-widget {
    @apply pt-16;
    min-height: 1195px;
    iframe {
      min-height: 1195px;
    }
  }
}
</style>
