<template>
  <div class="w-full bg-offwhite flex px-16 py-16 mobile:px-4">
    <div
      class="flex mobile:flex-col w-3/4 mx-auto px-4 mobile:w-full mobile:px-0"
    >
      <div class="w-1/2 mobile:w-full flex flex-col">
        <span class="text-5xl font-extrabold mobile:text-center mobile:mb-8"
          >Broneeri <span class="text-green">demo</span>.</span
        >
        <span class="font-semibold text-2xl my-4 mobile:text-center"
          >Kellele on mõeldud Gewodo demo?</span
        >
        <div class="process-list">
          <div class="process-list-item">
            <span class="process-point typcn typcn-tick"></span>
            <span class="process-text"
              >Kui soovid teada kas Gewodo sobib Sinu ettevõttele</span
            >
          </div>
          <div class="process-list-item">
            <span class="process-point typcn typcn-tick"></span>
            <span class="process-text"
              >Tahad nõu selles osas, et millist paketti valida</span
            >
          </div>
          <div class="process-list-item">
            <span class="process-point typcn typcn-tick"></span>
            <span class="process-text"
              >Soovid teada kuidas Gewodoga kiirelt jalad alla saada</span
            >
          </div>
          <div class="process-list-item">
            <span class="process-point typcn typcn-tick"></span>
            <span class="process-text"
              >Tahad teada kõike iga meie võimaluse kohta</span
            >
          </div>
        </div>
        <span class="text-xl font-semibold mb-2"
          >Tahad katsetada iseseisvalt?</span
        >
        <span class="text-sm mb-2"
          >Proovi Gewodo tööhaldust <span class="text-green">TASUTA</span>. Ei
          mingit kaardi lisamist. Õpitav
          <span class="text-green">15 minutiga</span>.</span
        >
        <div class="flex mb-2 mobile:flex-col medium:flex-col">
          <router-link
            :to="$store.state.auth.status.loggedIn ? '/calendar' : '/register'"
            class="mr-3"
          >
            <button
              class="bg-turquoise hover:bg-turquoisedark hover:shadow-turquoiseoutline border-2 border-turquoise text-white font-semibold text-xl py-2 px-4"
            >
              Proovi TASUTA
            </button></router-link
          >
          <router-link to="/product">
            <button
              class="bg-transparent border-2 py-2 px-4 text-xl font-semibold hover:bg-white hover:border-green hover:text-green hover:shadow-outline"
            >
              Tutvu tootega
            </button>
          </router-link>
        </div>

        <span class="mb-2 text-xs"
          >* proovi 14 päeva tasuta Premium paketti. Baaspakett ALATI tasuta.
          Vaata hinnastamist SIIT.</span
        >
      </div>
      <div class="w-1/2 flex justify-center items-center mobile:hidden">
        <img src="/header/about_us.png" class="rounded-xl" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: ""
    };
  }
};
</script>
